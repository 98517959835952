import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import auraExplodedView from '../assets/images/auraExplodedView.gif'
import auraAirApp from '../assets/images/auraAirApp.png'
import video from '@static/auraair.mp4';
import sectorImgOffice from '../assets/images/sectorImgOffice.jpg'
import auraControlPanel from '../assets/images/auraControlPanel.png';
import shopInstall from '../assets/images/shopInstall.jpg';
import officeInstall from '../assets/images/officeInstall.jpg';

const AirPurification = props => (
  <Layout>
    <Helmet>
      <title>Air Protection | Indoor Air Quality Monitoring & Purification</title>
      <meta
        name="description"
        content="Indoor Air Quality Monitoring & Purification Products Page"
      />
    </Helmet>

    <div id="airPurification">
      <section>
        <div className="inner" style={{ alignItems: 'flex-start' }}>
            <h2>Indoor Air Quality Monitoring & Purification</h2>
          <p>
          Introducing Aura Air, the world’s smartest and most effective “plug and play” air quality monitoring and purification technology
          that eliminates 99.9% of viruses [              <a
                href={`/auraAirCovid.pdf`}
                target="_blank"
                rel="noreferrer"
              >
              <strong style={{ color: 'black' }}>99.998%</strong> effective in eliminating SARS-CoV-2 (COVID- 19)
              </a>], bacteria, mould, and
          volatile organic compounds (VOC’s). Ideal for every indoor space, it also removes airborne particles as small as 0.3 microns,
          including allergens and viruses, so that you and your colleagues/family can breathe easy!
          </p>
          <br />
          <div style={{ maxWidth: '90%' }}>
            <video autoPlay muted loop playsInline controls id="video" className="content" style={{ width: '100%' }}>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <br />
          <h2>The Aura Air App - A Data-Driven Air Quality Platform</h2>
          <p>
            From its seven integrated high-precision sensors, Aura Air accurately measures and displays, in real time,
            key air quality index parameters (AQI), including: carbon dioxide (<strong style={{ color: 'black' }}>CO2</strong>);
            carbon monoxide (<strong style={{ color: 'black' }}>CO</strong>); volatile organic compounds
            (<strong style={{ color: 'black' }}>VOC’s</strong>); particulate matter up to 2.5 microns
            (<strong style={{ color: 'black' }}>pm2.5</strong>); particulate matter between 2.5 and 10 microns
            (<strong style={{ color: 'black' }}>pm10</strong>); temperature; and, humidity. All this information is displayed
            and can be managed simply via a desktop PC/MAC on the Aura Air for Business Dashboard, and/or, via Smartphone/Tablet on the
            Aura App (iOS/Android).
          </p>
          <img
            src={auraAirApp}
            alt="Aura Air Smartphone App"
            style={{ maxWidth: '75%', paddingBottom: '1em' }}
          />
          <div className="break"></div>
          <h2>The Technology</h2>
          <p>
            Aura Air is the first, all in one, Air Filtration, Disinfection, Purification, and Monitoring product that has successfully
            completed clinical tests specifically related to its 99.998% efficacy against the COVID-19 coronavirus.
          </p>
          <img
            src={auraExplodedView}
            alt="Aura Air Exploded View"
            style={{ maxWidth: '90%' }}
          />
          <h2>Pre-Filter</h2>
          <p>
            The Pre-Filter catches large particles of dust, pollen, insects, animal hair and other large particles. The Pre- Filter is very
            easy to maintain (a quick rinse under a tap once a month) and has a key role in conserving the quality of the device. The Aura App
            notifies users when this needs cleaning.
          </p>
          <h2>Ray-Filter™</h2>
          <p>The patented Ray-Filter™ consists of three parts:</p>
          <ul>
            <li>MERV16 rated HEPA filter which is {'>'}99.5% effective in filtering particles as small as 0.3 microns</li>
            <li>Carbon Layer Absorbs VOCs, smoke and bad odors</li>
            <li>Copper coating, which is anti-viral/anti-bacterial, neutralises viruses and bacteria</li>
          </ul>
          <h2>UVC LED</h2>
          <p>
            4 internal UVC LED’s radiate the air flowing through the Aura and are 99.96% effective in neutralising bacteria and viruses by
            destroying the cell membrane structure.
          </p>
          <h2>The Sterionizer&#8482;</h2>
          <p>
            The patented Sterionizer completes the purification and disinfection stage with 99.97% efficacy by sending positive and negative ions
            into the air to further neutralise and break down the cell membrane structure of viruses and bacteria
          </p>
          <img
            src={sectorImgOffice}
            alt="Aura Air in office setting"
            style={{ maxWidth: '80%', paddingBottom: '1em' }}
          />
          <br id="b2bapp" />
          <h2>Aura Air Business Dashboard</h2>
          <p>
            The Business Dashboard can monitor and manage the indoor air quality (IAQ) in real-time across unlimited Aura Air devices, be that at a
            single site location, or over numerous site locations. The IAQ in all your organisation’s offices, meeting rooms, staff rooms, classrooms,
            restaurants, salons, clinics, shops, etc, can be managed both locally and/or at a central/HQ location. The Dashboard can also be integrated with,
            or function as, a Building Management System (BMS), controlling and optimising the Heating Ventilation and Air Conditioning (HVAC) system – saving
            energy costs, whilst improving indoor air quality.
          </p>
          <img
            src={auraControlPanel}
            alt="Aura Control Panel"
            style={{ maxWidth: '80%' }}
          />
          <p>
            Custom IAQ alerts can also be configured to instantly send e-mail warning messages if pre-set conditions are exceeded, for example, if excessive levels
            of carbon dioxide or particulate matter are identified in a meeting room, which may increase the risk of viral contagion.
          </p>
          <img
            src={officeInstall}
            alt="Aura Office Install"
            style={{ maxWidth: '80%' }}
          />
          <p>
            At each location, by device, the real-time IAQ can also be shared and displayed on a PC, tablet, or Smart TV, and via a QR code to be viewed on a smart phone,
            for instance in a reception area or a meeting room, to provide reassurance for both staff and visitors.
          </p>
          <img
            src={shopInstall}
            alt="Aura Shop Install"
            style={{ maxWidth: '80%' }}
          />
          <br id="downloads" />
          <h2>Downloads</h2>
          <ul>
            <li>
              <a
                href={`/auraAirOverview.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Aura Air Overview
              </a>
            </li>
            <li>
              <a
                href={`/auraAirStand.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Aura Air Stand
              </a>
            </li>
            <li>
              <a
                href={`/auraAirUserManual.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                User manual
              </a>
            </li>
            <li>
              <a
                href={`/auraAirWiFiSpecifications.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Wi-Fi specifications
              </a>
            </li>
            <li>
              <a
                href={`/auraAirSpecifications.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Aura Air specifications
              </a>
            </li>
            <li>
              <a
                href={`/auraB2bGenericDashboardSetUp.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                B2B dashboard setup guide
              </a>
            </li>
            <li>
              <a
                href={`/protectingYourWorkingEnvironment.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Protecting your working environment
              </a>
            </li>
            <li>
              <a
                href={`/purificationTechnology.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Purification technology
              </a>
            </li>
            <li>
              Covid-19 effectiveness:
              <ul>
                <li>
                  <a
                    href={`/auraAirCovid.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Innovative Bioanalysis study - 99.998% effective in eliminating SARS-CoV-2 (COVID- 19)
                  </a>
                </li>
                <li>
                  <p>
                    Additionally, a July 2020 white paper from The Chaim Sheba Medical Center demonstrated >99.7% efficacy and concluded that,
                    “Each of the tested components of the Aura Air Device was able to significantly reduce the viral load as measured by PCR”.{' '}
                    <a
                      href={`/coronavirusWhitePaper.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      You can download the white paper here
                    </a>
                  </p>
            </li>
              </ul>
            </li>

          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default AirPurification
